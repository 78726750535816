
.logic-node {
  display: flex;
  max-width: 40vw;
  padding: 5px;
  background: white;
  border: 1px solid #eee;
  border-radius: 5px;
}

.question-node {
  display: flex;
  max-width: 40vw;
  padding: 5px;
  background: white;
  border: 1px solid #eee;
  border-radius: 5px;
}

.feature-node {
  display: flex;
  height: 75px;
  padding: 5px;
  background: white;
  border: 1px solid #eee;
  border-radius: 5px;
}

.react-flow .react-flow__handle {  /* stylelint-disable */
  width: 14px;
  height: 30px;
  background-color: var(--mantine-color-blue-6);
  border-radius: 12px;
}

.react-flow .react-flow__handle-left {
  left: -10px;
}

.react-flow .react-flow__handle-right {
  right: -10px;
}

.react-flow .react-flow__handle:hover {
  background-color: var(--mantine-color-blue-4);
}
