
.card {
  position: relative;
  z-index: 7;
  touch-action: none;
  cursor: grab;
  border: 10px solid white;
  border-radius: 5px;
  box-shadow: 0 10px 30px -5px rgb(0 0 0 / 30%);
  transition: box-shadow 0.5s, opacity 0.5s;
  will-change: transform;
}

.card:hover {
  box-shadow: 0 30px 100px -10px rgb(0 0 0 / 40%);
}

.bulkAffixContainer {
  pointer-events: none;
}

.bulk {
  position: relative;
  z-index: 7;
  pointer-events: auto;
  touch-action: none;
  cursor: grab;
  box-shadow: 0 10px 30px -5px rgb(0 0 0 / 30%);
  transition: box-shadow 0.5s, opacity 0.5s;
  will-change: transform;
}

.bulk:hover {
  box-shadow: 0 30px 100px -10px rgb(0 0 0 / 40%);
}

